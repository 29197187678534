import React from 'react';
import { Recipe } from '../components/Recipe';

export const Holidays = () => {
    return (
        <React.Fragment>
            <h3>Fourth of July Specials</h3>

            <Recipe
                name="Aperol Spritz"
                description="Not really an American drink, but refreshing and delicious nonetheless."
                ingredients={[
                    'Proseco',
                    'Aperol',
                    'Soda',
                    'Orange slice',
                ]}
            />

            <Recipe
                name="Amaro Soda"
                description={`Similar to an Aperol Spritz without the Proseco. Choose from a variety of Amari.`}
                ingredients={[
                    'Amaro',
                    'Soda',
                    'Lemon twist',
                ]}
            />

            <Recipe
                name="Americano"
                description={`Another Italian drink?? At least this one sounds American.`}
                ingredients={[
                    'Campari',
                    'Sweet vermouth',
                    'Soda',
                    'Orange slice',
                ]}
            />

            <Recipe
                name="Sippy Cup"
                description="Remember those coca-cola bottle gummy candies?"
                ingredients={[
                    'Averna',
                    'lime juice',
                    'Cocchi di Torino',
                    'ginger syrup',
                    'Angostura bitters'
                ]}
            />

            <h3>Mardi Gras Specials</h3>

            <Recipe
                name="Sazerac"
                description="The most famous cocktail to come out of New Orleans, created by Antoine Amédée Peychaud in the 1800s."
                ingredients={[
                    'Rye Whisky',
                    'simple syrup',
                    'Peychaud\'s bitters',
                    'absinthe rinse'
                ]}
            />

            <Recipe
                name="Vieux Carré"
                description={`Pronounced "voo car-ray", the name means "old square" and refers to the original name of the French Quarter. `}
                ingredients={[
                    'Rye whisky', 'Cognac', 'Bénédictine', 'sweet vermouth', 'bitters'
                ]}
            />

            <Recipe
                name="Ramos Gin Fizz"
                description="Originally known as the New Orleans Fizz, this cocktail was created by Henry C. Ramos in 1888 and became famous for its 12-minute mixing time."
                ingredients={[
                    'London dry gin',
                    'simple syrup',
                    'orange flower water',
                    'lemon juice',
                    'lime juice',
                    'heavy cream',
                    'egg white'
                ]}
            />

        </React.Fragment>
    );
};
