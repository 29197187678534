enum BaseSpirits {
    Gin = "Gin",
    Tequila = "Tequila",
    TequilaReposado = "Tequile Reposado",
    Bourbon = "Bourbon Whisky",
    PineappleRum = "Pineapple Rum",
    LondonDryGin = "London Dry Gin",
    Mezcal = "Mezcal",
    Rye = "Rye Whisky",
    Whisky = "Whisky",
    Vodka = "Vodka",
    DarkRum = "Dark rum",
}

enum Mixers {
    soda = "Soda water",
}

enum Bitters {
    orange = "Orange bitters",
    Angostura = "Angostura bitters",
    mole = "Xocolati Mole bitters",
    bitters = "bitters",
}

enum Liquers {
    Absinthe = "Absinthe",
    coffee = "Coffee liquer",
    Maraschino = "Luxardo Maraschino Liqueur",
    tripleSec = "Triple sec",
    Benedictine = "Bénédictine",
    Cointreau = "Cointreau",
    Liqor43 = "Liqor 43",
    Curacao = "Curaçao",
}

enum Garnishes {
    Cherry = "Brandied Cherry",
    Nutmeg = "Nutmeg",
}

enum Syrups {
    grenadine = "grenadine",
    honey = "honey",
    simple = "simple syrup",
    ginger = "giner syrup",
    agave = "agave syrup",
}

enum Vermouths {
    CocchiAmericano = "Cocchi Americano",
    CocchiTorino = "Cocchi di Torino",
    Sweet = "Sweet Vermouth",
    Dry = "Dry Vermouth",
}

enum Juice {
    lemon = "lemon juice",
    lime = "lime juice",
}

enum Herbs {
    mint = "mint",
}

enum Amari {
    Averna = "Averna",
    Meletti = "Meletti",
    Montenegro = "Montenegro",
    GreenChartreuse = "Green Chartreuse",
    Salers = "Salers Gentiane",
    Cynar = "Cynar",
}

enum Other {
    egg = "egg",
    espresso = "espresso",
    cream = "heavy cream",
}

type Ingredients =
    | Amari
    | Mixers
    | BaseSpirits
    | Bitters
    | Liquers
    | Garnishes
    | Syrups
    | Vermouths
    | Juice
    | Herbs
    | Other;

type Cocktail = {
    name: string;
    description?: string;
    ingredients: Ingredients[];
    tags?: string[];
};

export const cocktails: Cocktail[] = [
    {
        name: "Ellen's Whisky Smash",
        description: "It's quite nice!",
        ingredients: [
            BaseSpirits.Bourbon,
            Juice.lemon,
            Syrups.simple,
            Herbs.mint,
        ],
        tags: ["House Favorites"],
    },
    {
        name: "Kirkpatrick's Almost Perfect",
        description:
            "Created by James MacWilliams of Canlis, introduced to ek's in a birthday booze box.",
        ingredients: [
            BaseSpirits.PineappleRum,
            Vermouths.CocchiAmericano,
            Amari.Averna,
            Liquers.Maraschino,
            Syrups.grenadine,
            Bitters.Angostura,
        ],
        tags: ["House Favorites"],
    },
    {
        name: "MonteNegroni",
        description:
            "A classic 1:1:1 Negroni that uses Amaro Montenegro in place of Campari.",
        ingredients: [Amari.Montenegro, BaseSpirits.Gin, Vermouths.Sweet],
        tags: ["House Favorites"],
    },
    {
        name: "Last Word",
        description:
            "A classic prohibition era cocktail lost and then made famous by Murray Stenson of Seattle's Zig Zag Café.",
        ingredients: [
            BaseSpirits.LondonDryGin,
            Liquers.Maraschino,
            Amari.GreenChartreuse,
            Juice.lime,
        ],
    },
    {
        name: "Corpse Reviver No. 2",
        description:
            "Pre-prohibition cocktail meant to rouse the drinker from the dead; a perfect hangover cure.",
        ingredients: [
            BaseSpirits.Gin,
            Juice.lemon,
            Vermouths.CocchiAmericano,
            Liquers.tripleSec,
            Syrups.simple,
            Liquers.Absinthe,
        ],
    },
    {
        name: "Margarita",
        description: "On the rocks, with or without salt.",
        ingredients: [BaseSpirits.Tequila, Juice.lime, Syrups.simple],
        tags: ["Classics"],
    },
    {
        name: "Manhattan",
        description: `Made with bourbon or rye. If you are looking to mix it up, make it a "Black Manhattan" which uses Averna in place of sweet vermouth.`,
        ingredients: [
            BaseSpirits.Bourbon,
            Vermouths.Sweet,
            Bitters.Angostura,
            Garnishes.Cherry,
        ],
        tags: ["Classics"],
    },
    {
        name: "Sippy Cup",
        description: "Tastes kind of like a Coca Cola gummy!",
        ingredients: [
            Amari.Averna,
            Vermouths.CocchiTorino,
            Syrups.ginger,
            Juice.lime,
            Bitters.Angostura,
            Mixers.soda,
        ],
        tags: ["Summer"],
    },
    {
        name: "White Ferrari",
        description:
            "A crystal clear take on a mezcal negroni. One of Brian's favorites.",
        ingredients: [
            BaseSpirits.Mezcal,
            Amari.Salers,
            Vermouths.Dry,
            Vermouths.Sweet,
        ],
        tags: ["Sharon"],
    },
    {
        name: "Sforzando",
        description: "A complex and boozy drink orginating from Death & Co.",
        ingredients: [
            BaseSpirits.Rye,
            BaseSpirits.Mezcal,
            Liquers.Benedictine,
            Vermouths.Dry,
            Bitters.mole,
        ],
        tags: ["Sharon"],
    },
    {
        name: "Lucille",
        description:
            "A mezcal negroni orginating from Pebble Bar at Rockefeller Center.",
        ingredients: [
            BaseSpirits.Mezcal,
            Amari.Montenegro,
            Vermouths.CocchiTorino,
            Bitters.mole,
        ],
        tags: ["Sharon"],
    },
    {
        name: "Oxaxca Old-Fashioned",
        description: "Created by Phil Ward at Death & Co.",
        ingredients: [
            BaseSpirits.TequilaReposado,
            BaseSpirits.Mezcal,
            Bitters.Angostura,
            Syrups.agave,
        ],
        tags: ["Sharon"],
    },
    {
        name: "Cynar Flip",
        description:
            "Whisky meets Italian apertivo in this rich and creamy cocktail.",
        ingredients: [
            BaseSpirits.Whisky,
            Amari.Cynar,
            Liquers.Cointreau,
            Syrups.simple,
            Other.egg,
            Bitters.bitters,
        ],
        tags: ["Friday progressive", "Dessert"],
    },
    {
        name: "Julius Orange",
        description: "If you like this one, try an Orange Julius.",
        ingredients: [
            Liquers.Curacao,
            BaseSpirits.DarkRum,
            Juice.lemon,
            Syrups.simple,
            Other.cream,
            Bitters.orange,
            Garnishes.Nutmeg,
        ],
        tags: ["Friday progressive", "Dessert"],
    },
    {
        name: "Sicilian Cafe",
        description: "Be careful of the swirls!",
        ingredients: [
            BaseSpirits.Bourbon,
            Amari.Averna,
            Liquers.coffee,
            Syrups.honey,
            Bitters.orange,
        ],
        tags: ["Dessert"],
    },
    {
        name: "Espresso Martini",
        ingredients: [
            BaseSpirits.Vodka,
            Liquers.coffee,
            Other.espresso,
            Syrups.simple,
        ],
        tags: ["Dessert"],
    },
    {
        name: "Carajillo",
        ingredients: [Liquers.Liqor43, Other.espresso],
        tags: ["Dessert"],
    },
];
