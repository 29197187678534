import React from 'react';
import { Recipe } from '../components/Recipe';
import { cocktails } from "../cocktails";

export const Menu = () => {
    const fridayProgressive = cocktails.filter((cocktail) =>
        cocktail.tags?.includes("Friday progressive")
    );
    const dessert = cocktails.filter(
        (cocktail) =>
            cocktail.tags?.includes("Dessert") &&
            !fridayProgressive.includes(cocktail)
    );
    const houseFavorites = cocktails.filter((cocktail) =>
        cocktail.tags?.includes("House Favorites")
    );
    const classics = cocktails.filter((cocktail) =>
        cocktail.tags?.includes("Classics")
    );
    const otherCocktails = cocktails.filter(
        (cocktail) =>
            !houseFavorites.includes(cocktail) &&
            !classics.includes(cocktail) &&
            !fridayProgressive.includes(cocktail) &&
            !dessert.includes(cocktail)
    );
    return (
        <React.Fragment>
            <h3>Friday Progressive</h3>

            {fridayProgressive.map((cocktail) => (
                <Recipe key={cocktail.name} {...cocktail} />
            ))}

            <h3>Dessert</h3>

            {dessert.map((cocktail) => (
                <Recipe key={cocktail.name} {...cocktail} />
            ))}

            <h3>House Favorites</h3>

            {houseFavorites.map((cocktail) => (
                <Recipe key={cocktail.name} {...cocktail} />
            ))}

            <h3>Cocktails</h3>

            {otherCocktails.map((cocktail) => (
                <Recipe key={cocktail.name} {...cocktail} />
            ))}

            <h3>Classics</h3>

            {classics.map((cocktail) => (
                <Recipe key={cocktail.name} {...cocktail} />
            ))}
        </React.Fragment>
    );
};
