import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Nav } from './Nav';

export const StyledHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
        margin: 0;
    }

    h1 > a {
        text-decoration: none;

        &,
        &:hover,
        &:visited,
        &:focus {
            color: #fff;
        }
    }
`;

export const Header = () => {
    return (
        <StyledHeader>
            <h1><Link to="/">ek's</Link></h1>
            <Nav />
        </StyledHeader>
    );
};
