import React from 'react'

export const About = () => {
    return (
        <React.Fragment>
            <h3>About</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ornare lorem sit amet quam mattis, ac fringilla est commodo. Vestibulum rhoncus congue tempus. Vivamus cursus scelerisque nulla sit amet placerat. Morbi rhoncus dictum elementum. Nulla facilisi. Mauris porta sit amet erat a euismod. Duis lacus mauris, molestie et purus a, mollis ullamcorper velit. Aliquam accumsan, augue id sollicitudin posuere, magna nulla fermentum purus, eu pharetra odio tortor porttitor nisi. Vestibulum faucibus tellus sed magna sodales ultricies. Suspendisse potenti. Curabitur at eros porttitor, luctus quam quis, feugiat metus. Ut semper, tortor eget mattis euismod, enim mi rutrum risus, sit amet commodo diam metus at est. Nam vel urna nec felis mollis adipiscing non eget mauris. Cras auctor, nulla a iaculis interdum, arcu lorem mattis augue, eu porta diam ligula a dui. Morbi eu porttitor sapien. Donec fermentum justo eu ligula placerat lobortis.</p>
        </React.Fragment>
    );
};
