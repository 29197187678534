import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledNav = styled.nav`
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
    }
    li {
        margin-left: 1rem;
    }
    a {
        color: #fff;
    }
`;

export const Nav = () => {
    return (
        <StyledNav>
            <ul>
                <li><Link to="/menu">Menu</Link></li>
            </ul>
        </StyledNav>
    );
};
