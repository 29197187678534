import React from 'react';
import { createGlobalStyle } from 'styled-components';
import glitter from '../images/glitter.png';

const GlobalStyle = createGlobalStyle`
    html {
        height: 100%;
    }
    body {
        background-image: url(${glitter});
        background-repeat: no-repeat;
        background-position: bottom right;
        background-attachment: fixed;
    }
`;

export const Home = () => {
    return (
        <React.Fragment>
            <h3>A New York speakeasy in the heart of Greenlake.</h3>
            <GlobalStyle />
        </React.Fragment>
    );
};
