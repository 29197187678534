import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import { Header } from './components/Header';
import { About } from './routes/About';
import { Home } from './routes/Home';
import { Menu } from './routes/Menu';
import { Holidays } from './routes/Holidays';

const StyledApp = styled.div`
    max-width: 600px;
    margin: 0 auto;
    padding: 1rem;
`;

function App() {
    return (
        <Router>
            <StyledApp>
                <Header />
                <Switch>
                    <Route path="/about">
                        <About />
                    </Route>
                    <Route path="/menu">
                        <Menu />
                    </Route>
                    <Route path="/holidays">
                        <Holidays />
                    </Route>
                    <Route path="/">
                        <Home />
                    </Route>
                </Switch>
            </StyledApp>
        </Router>
    );
}

export default App;
